<template>
  <div class="putter-board padding-20" id="putter-board">
    <div class="putter-operation">
      <el-date-picker
        v-model="params.queryDate"
        value-format="yyyy-MM-dd"
        type="date"
        style="width: 150px; margin-right: 10px"
        :clearable="false"
        placeholder="选择时间"
        :picker-options="disableTodayOptions"
      >
      </el-date-picker>
      <div style="margin-right: 10px">
        <select-platform-channel :params="params"></select-platform-channel>
      </div>
      <el-select
        v-model="params.booksId"
        filterable
        remote
        clearable
        style="width: 150px; margin-right: 10px"
        reserve-keyword
        placeholder="书籍名称"
        :remote-method="getBook"
        @change="handleBookIdChange"
        @clear="handleBookClear"
      >
        <el-option
          v-for="item in bookList"
          :key="item.id"
          :label="item.bookName"
          :value="item.id"
        ></el-option>
      </el-select>
      <template v-if="userInfo.homeRole.includes('0')">
        <el-select
          v-model="params.adminUserId"
          placeholder="请选择投手"
          filterable
          style="width: 120px"
          clearable
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in putterList"
            :key="item.id"
          ></el-option>
        </el-select>
      </template>
      <el-button type="board" @click="handleSearch" style="margin-left: 10px"
        >搜索</el-button
      >
      <el-button type="info" @click="handleReset">重置</el-button>
    </div>
    <el-row :gutter="15">
      <el-col :span="7" class="left-part parts">
        <div class="today-put">
          <div class="today-put-container">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span class="card-title">今日投放</span>
              </div>
              <el-row :gutter="10" style="margin-top: 10px">
                <el-col
                  :span="12"
                  v-for="(item, index) in todayPutPart"
                  :key="index"
                >
                  <div class="put-item" @click="handleShowDrawer(item)">
                    <h4 :class="`put-${index + 1}`">{{ item.name }}</h4>
                    <div class="put-item-value">
                      <span class="total-value">{{ item.value }}</span>
                      <template v-if="!['广告', '计划'].includes(item.name)">
                        <template v-if="item.trend === 'up'">
                          <span class="change-value-red">
                            <i class="iconfont icon-shangsheng"></i>
                            <span>{{ item.trendVal }}</span>
                          </span>
                        </template>
                        <template v-else>
                          <span class="change-value-green">
                            <i class="iconfont icon-xiajiang"></i>
                            <span>{{ item.trendVal }}</span>
                          </span>
                        </template>
                      </template>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </div>
          <div class="sq-graph">
            <el-row :gutter="2">
              <el-col :span="12">
                <p
                  class="title"
                  :style="`color:${subTitleColor};font-size:${subTitleFontSize};font-weight:bold`"
                >
                  书籍消耗分布
                </p>
                <div id="book-consume"></div>
              </el-col>
              <el-col :span="12">
                <p
                  class="title"
                  :style="`color:${subTitleColor};font-size:${subTitleFontSize};font-weight:bold`"
                >
                  公众号消耗分布
                </p>
                <div id="channel-consume"></div>
              </el-col>
            </el-row>
          </div>

          <div class="book-rate">
            <el-row :gutter="2">
              <el-col
                :span="24"
                :style="`color:${subTitleColor};font-size:${subTitleFontSize}`"
              >
                <p class="title">投放转换率</p>
                <div id="delivery-conversion-rate"></div>
              </el-col>
            </el-row>
          </div>

          <div class="follower-info">
            <el-row :gutter="2">
              <el-col
                :span="24"
                :style="`color:${subTitleColor};font-size:${subTitleFontSize}`"
              >
                <p class="title" style="font-weight: bold">时点关注人数</p>
                <div id="follower"></div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>

      <el-col :span="10" class="middle-part parts">
        <div class="today-recharge">
          <el-card class="box-card">
            <div slot="header" class="clearfix" style="cursor: pointer">
              <span class="card-title" @click="showDuPont = true"
                >今日充值</span
              >
            </div>

            <el-row :gutter="10" style="margin-top: 10px">
              <div class="field-parts">
                <div
                  class="field-part"
                  v-for="(item, index) in fieldList"
                  :key="index"
                >
                  <p class="text-center">{{ item.cname }}</p>
                  <p
                    class="text-center"
                    :class="[
                      ['rechargeMounting', 'returnRate'].includes(item.name)
                        ? 'orange-color'
                        : '',
                    ]"
                  >
                    {{ item.value }}
                  </p>
                </div>
              </div>
              <el-row
                :gutter="5"
                type="flex"
                justify="center"
                style="margin-top: 10px"
              >
                <el-col :span="20">
                  <PercentageRuler
                    :percentage="getReturnRate()"
                    :stroke-width="10"
                  />
                </el-col>
              </el-row>
            </el-row>
          </el-card>
        </div>

        <div class="today-recharge-bottom-part">
          <div class="title">D1回本不达标书籍</div>
          <div class="book-list">
            <template v-if="unAchievementBookList.length > 0">
              <div
                class="book-item line-overflow"
                v-for="(item, index) in unAchievementBookList.slice(0, 5)"
                :key="index"
              >
                {{ item }}
              </div>
              <div
                class="book-item check-more"
                v-if="unAchievementBookList.length > 5"
                @click="checkMore"
              >
                <el-popover placement="right" width="280" trigger="click">
                  <el-input
                    v-model="unBookName"
                    placeholder="请输入书籍名称"
                    style="margin-bottom: 10px"
                  ></el-input>
                  <div class="check-all">
                    <span
                      class="item"
                      v-for="(item, index) in copyUnAchievementBookList"
                      :key="index"
                      >{{ item }}</span
                    >
                  </div>
                  <a
                    class="region-item"
                    href="javascript:;"
                    slot="reference"
                    style="margin-left: 5px"
                    >查看全部</a
                  >
                </el-popover>
              </div>
            </template>
            <template v-else>
              <div style="font-weight: bold; font-size: 12px">暂无数据</div>
            </template>
          </div>
          <div class="user-info">
            <el-row :gutter="10" style="flex" justify="space-between">
              <el-col :span="12">
                <div class="title">会员充值分布</div>
                <div id="vip-recharge"></div>
              </el-col>
              <el-col :span="12">
                <div class="title">客户类型充值分布</div>
                <div id="user-category"></div>
              </el-col>
            </el-row>
          </div>
          <div class="days-payconsume-15">
            <div id="days-payconsume-15"></div>
          </div>
          <div class="days-payback-15">
            <div id="days-payback-15"></div>
          </div>
        </div>
      </el-col>

      <el-col :span="7" class="right-container">
        <div class="total-return">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card-title">
                <h4 class="title">累计回本</h4>
                <div class="card-title-tab" @click="handleTotalReturnTabChange">
                  <span
                    data-id="1"
                    :class="totalReturnTab === 1 ? 'active' : ''"
                    >累计</span
                  >
                  <span
                    data-id="2"
                    :class="totalReturnTab === 2 ? 'active' : ''"
                    >上月</span
                  >
                  <span
                    data-id="3"
                    :class="totalReturnTab === 3 ? 'active' : ''"
                    >本月</span
                  >
                  <span
                    data-id="4"
                    :class="totalReturnTab === 4 ? 'active' : ''"
                    >近一月</span
                  >
                </div>
              </span>
            </div>
            <el-row :gutter="10">
              <el-row
                :gutter="5"
                type="flex"
                justify="center"
                style="margin-top: 10px"
              >
                <el-col :span="24">
                  <div class="charge-bar">
                    <div
                      class="bar-item3"
                      style="line-height: 25px"
                      v-for="(item, index) in totalReturnRoi"
                      :key="index"
                    >
                      <p class="title">{{ item.name }}</p>
                      <p
                        class="value"
                        :class="item.name.trim() === 'roi' ? 'returnRate' : ''"
                      >
                        {{ formatThousandsOne(item.value)
                        }}{{ item.name.includes("roi") ? "%" : "万" }}
                      </p>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="0" style="margin-top: 10px">
                <el-col :span="24">
                  <div id="MonthRoi"></div>
                </el-col>
              </el-row>

              <el-row
                :gutter="0"
                style="margin-top: 10px; margin-left: 10px"
                class="books-roi"
              >
                <el-col :span="24">
                  <div class="title">
                    <span
                      class="title-text"
                      :style="
                        'color:' +
                        subTitleColor +
                        ';font-size:' +
                        subTitleFontSize
                      "
                      >书籍ROI(万)</span
                    >
                    <el-button-group class="change-page">
                      <span class="page-value"
                        >{{ booksPage }}/{{ booksRoiTotalPage }}</span
                      >
                      <el-button
                        size="mini"
                        icon="el-icon-arrow-left"
                        style="
                          border-radius: 5px 0 0 5px;
                          background-color: #c0ccda;
                        "
                        @click="handleTablePageChange('back', 'books')"
                      ></el-button>
                      <el-button
                        size="mini"
                        style="
                          border-radius: 0 5px 5px 0;
                          background-color: #c0ccda;
                        "
                        @click="handleTablePageChange('go', 'books')"
                        ><i class="el-icon-arrow-right el-icon--right"></i
                      ></el-button>
                    </el-button-group>
                  </div>
                </el-col>
                <el-col :span="24">
                  <el-table
                    :data="booksRoiPageData"
                    height="180"
                    @sort-change="handleBookSortChange"
                  >
                    <el-table-column
                      label="书籍"
                      min-width="50"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        <div>
                          {{ scope.row.name }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="投放"
                      prop="putAmt"
                      min-width="54"
                      sortable="custom"
                    ></el-table-column>
                    <el-table-column
                      label="回本"
                      prop="returnAmt"
                      min-width="54"
                      sortable="custom"
                    ></el-table-column>
                    <el-table-column
                      label="利润"
                      prop="profitAmt"
                      min-width="54"
                      sortable="custom"
                    ></el-table-column>
                    <el-table-column
                      label="ROI"
                      width="120"
                      prop="roi"
                      sortable="custom"
                    >
                      <template slot-scope="scope">
                        {{ formatThousandsOne(scope.row.roi) }}%
                        <el-progress
                          :percentage="getpercentage(scope.row)"
                          color="#579BF4"
                          :show-text="false"
                        ></el-progress>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>

              <el-row
                :gutter="0"
                style="margin-top: 15px; margin-left: 10px"
                class="books-roi"
              >
                <el-col :span="24">
                  <div class="title">
                    <span
                      class="title-text"
                      :style="
                        'color:' +
                        subTitleColor +
                        ';font-size:' +
                        subTitleFontSize
                      "
                      >公众号ROI(万)</span
                    >
                    <el-button-group class="change-page">
                      <span class="page-value"
                        >{{ channelsPage }}/{{ channelsRoiTotalPage }}</span
                      >
                      <el-button
                        size="mini"
                        style="
                          border-radius: 5px 0 0 5px;
                          background-color: #c0ccda;
                        "
                        @click="handleTablePageChange('back', 'channels')"
                        icon="el-icon-arrow-left"
                      ></el-button>
                      <el-button
                        size="mini"
                        style="
                          border-radius: 0 5px 5px 0;
                          background-color: #c0ccda;
                        "
                        @click="handleTablePageChange('go', 'channels')"
                        ><i class="el-icon-arrow-right el-icon--right"></i
                      ></el-button>
                    </el-button-group>
                  </div>
                </el-col>
                <el-col :span="24">
                  <el-table
                    :data="channelsRoiPageData"
                    @sort-change="handleChannelSortChange"
                    height="180"
                  >
                    <el-table-column
                      label="公众号"
                      min-width="50"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        <div>
                          {{ scope.row.name }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="投放"
                      prop="putAmt"
                      min-width="54"
                      sortable="custom"
                    ></el-table-column>
                    <el-table-column
                      label="回本"
                      prop="returnAmt"
                      min-width="54"
                      sortable="custom"
                    ></el-table-column>
                    <el-table-column
                      label="利润"
                      prop="profitAmt"
                      min-width="54"
                      sortable="custom"
                    ></el-table-column>
                    <el-table-column
                      label="ROI"
                      prop="roi"
                      sortable="custom"
                      width="120"
                    >
                      <template slot-scope="scope">
                        {{ formatThousandsOne(scope.row.roi) }}%
                        <el-progress
                          :percentage="getpercentage(scope.row)"
                          color="#579BF4"
                          :show-text="false"
                        ></el-progress>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </el-row>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <common-drawer
      :showDrawer="showCommonDrawer"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />

    <el-drawer
      class="duPont-drawer"
      title="小说投放概况(杜邦图分析)"
      :visible.sync="showDuPont"
      @close="showDuPont = false"
      size="100%"
      center
    >
      <DuPontPicture :show="showDuPont" :lastParams="params" />
    </el-drawer>
  </div>

  <!-- <div class="no-date">
      <img src="../../../../assets/noDate.png" class="" alt="" />
      <p class="no-data-text">功能暂未开放</p>
    </div> -->
</template>
<script>
import SelectPlatformChannel from "@/components/SelectPlatformChannel.vue";
import PercentageRuler from "../../components/ruler";
import DuPontPicture from "@/components/duPontPicture";

import { getBookList } from "@/api/account";
// import { platformList } from "@/assets/js/options.js";
import { disableTodayOptions } from "@/assets/js/options";
import { mapGetters, mapActions } from "vuex";
import {
  formatThousandsnoz,
  formatThousandsOne,
  formatTime,
  getPreMonth,
  getPresentMonth,
  getRecentlyMonth,
} from "@/assets/js/utils.js";
import {
  getPutBoard,
  operateTotalReturnRoi,
  operateMonthRoi,
} from "@/api/monitor";
import echarts from "echarts";
export default {
  name: "putterBoard",
  data() {
    return {
      formatThousandsOne,
      formatTime,
      params: {},
      nextParams: {},
      booksId: null, // 书籍Id
      booksCost: null,
      bookConsume: null,
      channelCost: null,
      channelConsume: null,
      putConversionRateData: null,
      deliveryConversionRate: null,
      timePoint: null, // 时点数据
      timePointInfo: null,
      vipRecharge: [],
      customerType: [],
      areaGraphData: null,
      conversionGraph: null,
      pieUserCategory: null,
      pieVip: null,
      bookList: [],
      followerOptions: null,
      todayPutPart: [],
      showCommonDrawer: false,
      // 今日充值字段展示
      fieldList: [],
      // 未达标书籍
      unAchievementBookList: [],
      copyUnAchievementBookList: [],
      subTitleColor: "#969696",
      subTitleFontSize: "12px",
      roiLoaidng: false,
      booksRoi: null,
      channelsRoi: null,
      totalReturnTab: 1,
      totalReturnRoi: [],
      // 月度roi
      MonthRoi: null,
      MonthRoiData: null,
      booksPage: 1,
      booksRoiTotalPage: 0,
      booksRoiPageSize: 20,
      booksRoiPageData: [],
      channelsRoi: [],
      channelsPage: 1,
      channelsRoiPageSize: 20,
      channelsRoiTotalPage: 0,
      channelsRoiPageData: [],
      sortName: null,
      sortDesc: null,
      dataType: null,
      showTemplate: "",
      showDuPont: false,
      unBookName: "",
      disableTodayOptions,
    };
  },
  computed: {
    ...mapGetters(["officialList", "userInfo", "userList"]),
    putterList: {
      get() {
        return this.userList.filter(
          (item) => "isPut" in item && item.isPut === 1
        );
      },
      set() {},
    },
  },
  watch: {
    unBookName: {
      handler(newV) {
        this.copyUnAchievementBookList = newV
          ? this.unAchievementBookList.filter((item) => item.includes(newV))
          : this.unAchievementBookList;
      },
    },
  },
  async mounted() {
    this.getBook();
    await this.initData("created");
    window.onresize = (e) => {
      this.bookConsume.resize();
      this.channelConsume.resize();
      this.deliveryConversionRate.resize();
      this.followerOptions.resize();
      this.MonthRoi.resize();
      this.fifteenContrast.resize();
      this.fifteenPayBack.resize();
    };
    this.$on("hook:beforeDestroy", () => {
      window.onresize = null;
    });
  },
  methods: {
    async initData(flag) {
      await this.getData(flag);
      await this.initGraph();
    },
    async getData(flag) {
      if (flag === "created") {
        // 缓存获取
        const tmpIndex = this.$store.state.routerHistory.findIndex(
          (item) => item.path === this.$route.path
        );
        // 若存在缓存
        if (tmpIndex !== -1) {
          this.params = this.$store.state.routerHistory[tmpIndex].params;
          // 若无查询时间 添加默认查询时间
          if (!this.params.queryDate) {
            this.$set(
              this.params,
              "queryDate",
              formatTime(new Date(), "yyyy-MM-dd")
            );
            // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
          }
          this.getBookById(this.params.booksId);
        } else {
          // 不存在缓存 初始化筛选条件
          this.$set(
            this.params,
            "queryDate",
            formatTime(new Date(), "yyyy-MM-dd")
          );
        }

        if (this.userInfo.homeRole.includes("0")) {
          this.$set(this.params, "adminUserId", this.putterList[0].id);
        } else {
          this.$set(this.params, "adminUserId", this.userInfo.id);
        }
      }

      let res = await getPutBoard({
        originPlatform: this.params.originPlatform,
        accountChannelId: this.params.accountChannelId,
        booksId: this.params.booksId,
        adminUserId: this.params.adminUserId,
        queryDate: this.params.queryDate,
      });
      let {
        bookCost, // 书籍消耗分布
        channelCost, // 公众号消耗
        customerType, //	客户类型充值分布 单位：万
        vipRecharge, //	会员充值分布 单位：万
        data,
        floorBooks, // D1回本不达标书籍
        teamCost, // 团队消耗分布 单位：万
        conversionGraph, // 近15日D1回本率趋势
        platformCost, // 平台消耗分布
        putConversionRate,
        areaGraph,
        timePoint,
        data: {
          cashCostAmt,
          cashCostAmtMounting,
          channelNum,
          channelNumMounting,
          booksNum,
          booksNumMounting,
          adNum,
          newUser, // *增粉
          newUserPrice, // *单粉价
          todayRechargeAmt, //* 今日充值
          returnRate, // *回本率
          rechargeMounting, // * 环比
          firstAmt, // * D1回本
          orderCnt, // *(订)单量
          payNum, // * 付费人数
        },
      } = res;

      this.todayPutPart = [
        {
          eName: "cashCost",
          name: "现金投放",
          value: `${formatThousandsOne(cashCostAmt)}万`,
          trend: Number(cashCostAmtMounting) >= 0 ? "up" : "down",
          trendVal: `${cashCostAmtMounting}%`,
        },
        {
          eName: "channelNum",
          name: "公众号",
          value: `${channelNum}`,
          trend: Number(channelNumMounting) >= 0 ? "up" : "down",
          trendVal: channelNumMounting,
        },
        {
          eName: "booksNum",
          name: "书籍",
          value: `${booksNum}`,
          trend: Number(booksNumMounting) >= 0 ? "up" : "down",
          trendVal: booksNumMounting,
        },
        {
          eName: "adNum",
          name: "广告",
          value: `${adNum}`,
        },
      ];
      this.fieldList = [
        {
          name: "cashCostAmt",
          cname: "现金投放",
          value: `${cashCostAmt}万`,
        },
        {
          name: "newUser",
          cname: "增粉",
          value: `${newUser}`,
        },
        {
          name: "newUserPrice",
          cname: "单粉价",
          value: newUserPrice,
        },
        {
          name: "todayRechargeAmt",
          cname: "今日充值",
          value: `${todayRechargeAmt}万`,
        },
        {
          name: "rechargeMounting",
          cname: "环比",
          value: `${rechargeMounting}%`,
        },
        {
          name: "firstAmt",
          cname: "D1回本",
          value: `${firstAmt}万`,
        },
        {
          name: "orderCnt",
          cname: "单量",
          value: orderCnt,
        },
        {
          name: "payNum",
          cname: "付费人数",
          value: payNum,
        },
        {
          name: "returnRate",
          cname: "回本率",
          value: `${returnRate}%`,
        },
      ];

      this.timePoint = this.deepClone(timePoint);
      const putConversion = [
        {
          name: "clkPv",
          cname: "点击",
        },
        {
          name: "expPv",
          cname: "曝光",
        },
        {
          name: "newUser",
          cname: "增粉",
        },
        {
          name: "payNum",
          cname: "付费",
        },
      ];

      this.areaGraphData = this.deepClone(areaGraph);

      this.conversionGraph = this.deepClone(conversionGraph);

      let putConversionRateData = Object.keys(putConversionRate).map(
        (propertyName) => {
          const target = putConversion.find((key) => key.name === propertyName);
          return {
            name: target.cname,
            trueValue: Number(
              putConversionRate[target.name].split(",").join("")
            ),
          };
        }
      );

      let maxValue = Math.max(
        ...putConversionRateData.map((item) => Number(item.trueValue))
      );
      let maxIndex = putConversionRateData.findIndex(
        (item) => Number(item.trueValue) === maxValue
      );
      let copyPutCoversionRateData = this.deepClone(putConversionRateData);
      putConversionRateData.forEach((item) => {
        if (item.name === "曝光") {
          item.value = item.trueValue;
        }
        if (item.name === "点击") {
          item.value = maxValue * 0.8;
        }
        if (item.name === "增粉") {
          item.value = maxValue * 0.6;
        }
        if (item.name === "付费") {
          item.value = maxValue * 0.4;
        }
        item.truePercent = (item.trueValue / maxValue).toFixed(4);
      });

      this.putConversionRateData = putConversionRateData;

      this.unAchievementBookList = [...this.deepClone(floorBooks)];
      this.copyUnAchievementBookList = JSON.parse(
        JSON.stringify(this.unAchievementBookList)
      );
      this.booksCost = this.deepClone(bookCost);

      this.channelCost = this.deepClone(channelCost);
      this.vipRecharge = [];
      this.customerType = [];
      Object.entries(this.deepClone(vipRecharge)).forEach(([key, value]) => {
        if (key === "noVipRechargeAmt") {
          this.vipRecharge.push({
            value,
            name: "非会员",
          });
        } else {
          this.vipRecharge.push({
            value,
            name: "会员",
          });
        }
      });
      Object.entries(this.deepClone(customerType)).forEach(([key, value]) => {
        if (key === "newCustomer") {
          this.customerType.push({
            value,
            name: "新客户",
          });
        } else {
          this.customerType.push({
            value,
            name: "老客户",
          });
        }
      });

      // *累计回本内容
      this.getoperateTotalReturnRoi();
    },
    initGraph() {
      this.initSqGraph();
      this.initChannelGraph();
      this.initFunnelGraph();
      this.initFollower();
      this.initPieGraphPartOne();
      this.initFifteenPayBack();
      this.initFifteenContrast();
      // this.initmonthlyROI();
    },
    getReturnData() {
      // !一次加载20条 防止卡顿
      this.booksRoi = returnData.booksRoi.slice(
        this.curCountOne * 20,
        (this.curCountOne + 1) * 20
      );
      returnData.channelsRoi = JSON.parse(
        JSON.stringify(returnData.booksRoi.slice(45))
      );
      this.channelsRoi = returnData.channelsRoi.slice(
        this.curCountTwo * 20,
        (this.curCountTwo + 1) * 20
      );
    },
    initSqGraph() {
      const len = this.booksCost.length;
      const value = this.deepClone(this.booksCost)
        .map((item) => {
          return {
            value: Number(item.cashCostAmt),
            name: item.bookName,
            booksId: item.booksId,
          };
        })
        .sort((a, b) => a.value - b.value)
        .reverse();

      const data = this.deepClone(value);
      const colors = [];
      const customColors = [
        "#81b6fa",
        "#6273E0",
        "#3F80FF",
        "#AB74E9",
        "#FF9163",
        "#FFB85E",
        "#00BF8A",
        "#FB817F",
      ];
      for (let i = 0; i < Math.ceil(len / customColors.length); i++) {
        colors.push(...customColors);
      }
      const options = {
        tooltip: {
          formatter: (info) => {
            return `<div class='tooltip-title'><p>书名:${info.name}</p>
            <p>消耗:${info.value}万</p>
            </div>`;
          },
        },
        color: colors,
        legend: {
          show: true,
        },
        series: [
          {
            left: "left",
            width: "100%",
            breadcrumb: {
              show: false,
            },
            top: "10%",
            bottom: "0",
            roam: false,
            nodeClick: false,
            type: "treemap",
            label: {
              show: true,
              formatter: "{b}",
            },
            data,
          },
        ],
      };
      if (this.bookConsume) {
        this.bookConsume.dispose();
      }

      this.bookConsume = echarts.init(document.getElementById("book-consume"));
      this.bookConsume.setOption(options);
      this.bookConsume.on("click", "series", (info) => {
        this.params.booksId = info.data.booksId;
        this.$set(this.params, "booksId", info.data.booksId);
        this.getBookById(this.params.booksId);
        this.initData();
      });
    },
    initChannelGraph() {
      const len = this.channelCost.length;
      const value = this.deepClone(this.channelCost)
        .map((item) => {
          return {
            value: Number(item.cashCostAmt),
            name: item.channelName,
            accountChannelId: item.accountChannelId,
          };
        })
        .sort((a, b) => a.value - b.value)
        .reverse();

      const data = this.deepClone(value);
      const colors = [];
      const customColors = [
        "#81b6fa",
        "#6273E0",
        "#3F80FF",
        "#AB74E9",
        "#FF9163",
        "#FFB85E",
        "#00BF8A",
        "#FB817F",
      ];
      for (let i = 0; i < Math.ceil(len / customColors.length); i++) {
        colors.push(...customColors);
      }
      const options = {
        tooltip: {
          formatter: (info) => {
            return `<div class='tooltip-title'>${info.name}</p>
            <p>消耗:${info.value}万</p>
            </div>`;
          },
        },
        color: colors,
        legend: {
          show: true,
        },
        series: [
          {
            left: "left",
            width: "100%",
            breadcrumb: {
              show: false,
            },
            top: "10%",
            bottom: "0",
            roam: false,
            nodeClick: false,
            type: "treemap",
            label: {
              show: true,
              formatter: "{b}",
            },
            data,
          },
        ],
      };
      if (this.channelConsume) {
        this.channelConsume.dispose();
      }

      this.channelConsume = echarts.init(
        document.getElementById("channel-consume")
      );
      this.channelConsume.setOption(options);
      this.channelConsume.on("click", "series", (info) => {
        this.$set(this.params, "accountChannelId", info.data.accountChannelId);
        // this.getBookById(this.params.booksId)
        this.initData();
      });
    },
    initFunnelGraph() {
      const options = {
        tooltip: {
          trigger: "item",
          formatter: (info) => {
            return `<div>${info.name}:${formatThousandsnoz(
              info.data.trueValue
            )}<br/>${
              info.data.trueValue !== 0
                ? (info.data.truePercent * 100).toFixed(2)
                : "0"
            }%</div>`;
          },
        },
        color: ["#6998f7", "#3F80FF", "#8aaff8", "#b8cffc", "#ccdaf7"],
        series: [
          {
            type: "funnel",
            left: "left",
            top: "10%",
            bottom: "0",
            width: "100%",
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",
            gap: 0,
            label: {
              show: true,
              position: "inside",
              formatter: (info) => {
                return `${info.name}\n\n${formatThousandsnoz(
                  info.data.trueValue
                )}  ${
                  info.data.trueValue !== 0
                    ? (info.data.truePercent * 100).toFixed(2)
                    : "0"
                }%`;
              },
            },
            data: this.putConversionRateData,
          },
        ],
      };
      if (!this.deliveryConversionRate) {
        this.deliveryConversionRate = echarts.init(
          document.getElementById("delivery-conversion-rate")
        );
      }
      this.deliveryConversionRate.setOption(options);
    },
    initFollower() {
      const followerOptions = {
        legend: {
          data: ["付费人数", "时点ROI"],
          top: "10%",
        },
        grid: {
          left: "-2.5%",
        },
        color: ["red", "#29baff"],
        tooltip: {
          trigger: "axis",
          formatter: "{a0}: {c0}<br />{a1}: {c1}%",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: this.timePoint.date,
          axisLabel: {
            fontSize: 8,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#8492A6", //更改坐标轴颜色
            },
          },
        },
        yAxis: [
          {
            type: "value",
            show: false,
            min: 0,
            // max: 3000
          },
          {
            type: "value",
            show: false,
            min: 0,
            // max: 20
          },
        ],
        series: [
          {
            name: "付费人数",
            data: this.timePoint.payNum,
            type: "line",
            smooth: true,
            yAxisIndex: 0,
            symbol: "none",
          },
          {
            name: "时点ROI",
            type: "bar",
            data: this.timePoint.roi,

            yAxisIndex: 1,
          },
        ],
      };
      const follower = document.getElementById("follower");
      this.followerOptions = echarts.init(follower);
      this.followerOptions.setOption(followerOptions);
    },
    initPieGraphPartOne() {
      const option = {
        tooltip: {
          formatter: "{b}:{c}万<br/>{d}%",
        },
        color: ["#579BF4", "#FFB85E"],
        series: [
          {
            type: "pie",
            radius: ["40%", "55%"],
            avoidLabelOverlap: false,
            right: "0",
            emphasis: {
              label: {
                show: true,
                fontWeight: "bold",
                fontSize: "12",
              },
            },
            label: {
              normal: {
                show: true,
                formatter: "{b}\n{c}万",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "12",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
                length: "5",
                length2: "17",
              },
            },
            data: this.vipRecharge,
          },
        ],
      };
      const optionCategory = JSON.parse(JSON.stringify(option));
      optionCategory.color = ["#FFB85E", "#579BF4"];
      optionCategory.series[0].data = this.customerType;
      if (!this.pieVip) {
        this.pieVip = echarts.init(document.getElementById("vip-recharge"));
      }
      this.pieVip.setOption(option);
      if (!this.pieUserCategory) {
        this.pieUserCategory = echarts.init(
          document.getElementById("user-category")
        );
      }
      this.pieUserCategory.setOption(optionCategory);
    },
    initFifteenContrast() {
      const option = {
        title: {
          text: "近15日消耗vsD1回本面积图",
          textStyle: {
            fontSize: 12,
            color: "#A7A7A7",
          },
        },
        legend: {
          icon: "circle",
          data: ["现金消耗", "回本金额"],
          right: "10%",
          textStyle: {
            color: this.subTitleColor,
            fontWeight: "bold",
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{a0}: {c0}万<br />{a1}: {c1}万",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 8,
          },
          axisLine: {
            lineStyle: {
              color: "#8492A6", //更改坐标轴颜色
            },
          },
          data: this.areaGraphData.date.map((item) => item.slice(5)),
        },
        yAxis: {
          type: "value",
          show: false,
        },
        grid: {
          left: "left",
          right: "6%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            data: this.areaGraphData.data[0].value,
            name: "现金消耗",
            type: "line",
            areaStyle: {},
            lineStyle: {
              color: "#579BF4",
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#579BF4",
                  },
                  {
                    offset: 1,
                    color: "#fcf4f4",
                  },
                ],
              },
            },
            symbol: "none",
          },
          {
            data: this.areaGraphData.data[1].value,
            name: "回本金额",
            type: "line",
            areaStyle: {},
            symbol: "none",
            lineStyle: {
              color: "#FFB85E",
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#FFB85E",
                  },
                  {
                    offset: 1,
                    color: "#fcf4f4",
                  },
                ],
              },
            },
          },
        ],
      };
      if (!this.fifteenContrast) {
        this.fifteenContrast = echarts.init(
          document.getElementById("days-payconsume-15")
        );
      }
      this.fifteenContrast.setOption(option);
    },
    initFifteenPayBack() {
      const option = {
        title: {
          text: "近15日D1回本率",
          textStyle: {
            fontSize: 12,
            color: "#969696",
          },
        },
        color: ["#579BF4", "#FFB85E"],
        legend: {
          icon: "circle",
          data: ["D1回本率", "平台30日均线"],
          right: "10%",
          textStyle: {
            color: this.subTitleColor,
            fontWeight: "bold",
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{a0}: {c0}%<br />{a1}: {c1}%",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "left",
          right: "4%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 8,
          },
          axisLine: {
            lineStyle: {
              color: "#8492A6", //更改坐标轴颜色
            },
          },
          data: this.conversionGraph.date.map((item) => item.slice(5)),
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            name: "D1回本率",
            data: this.conversionGraph.data[0].value,
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#579BF4",
            },
            symbol: "none",
          },
          {
            name: "平台30日均线",
            data: this.conversionGraph.data[1].value,
            type: "line",

            lineStyle: {
              color: "#FFB85E",
            },
            symbol: "none",
          },
        ],
      };
      if (!this.fifteenPayBack) {
        this.fifteenPayBack = echarts.init(
          document.getElementById("days-payback-15")
        );
      }
      this.fifteenPayBack.setOption(option);
    },
    initmonthlyROI() {
      let tmpSeries = this.MonthRoiData.y.map((item) => {
        return {
          symbol: "none",
          color: item.name === "ROI" ? "#579BF4" : "#FFB85E",
          name: item.name,
          barWidth: 20,
          data: item.data,
          type: item.name === "ROI" ? "bar" : "line",
          itemStyle: {
            normal: {
              barBorderRadius: [5, 5, 0, 0],
              lineStyle: {
                width: 2, //设置线条粗细
              },
              label: {
                show: false, //开启显示
                position: "insideTop", //在上方显示
                textStyle: {
                  //数值样式
                  color: "#fff",
                  fontSize: this.subTitleColor,
                },
              },
            },
          },
        };
      });
      if (!this.MonthRoi) {
        this.MonthRoi = echarts.init(document.getElementById("MonthRoi"));
      }
      this.MonthRoi.setOption(
        {
          title: {
            subtext: "分月ROI",
            subtextStyle: {
              fontSize: 12,
              fontWeight: "bold",
              color: this.subTitleColor,
            },
          },
          tooltip: {
            trigger: "axis",
            formatter: "roi：{c}%<br/>{b}",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            show: false,
            icon: "circle",
            right: "10%",
            data: ["ROI"],
            textStyle: {
              fontWeight: "bold",
              fontSize: this.subTitleFontSize,
            },
          },
          xAxis: {
            axisLabel: {
              fontSize: 8,
            },
            axisTick: {
              show: false,
            },
            data: this.MonthRoiData.x,
            axisLine: {
              lineStyle: {
                color: "#8492A6", //更改坐标轴颜色
              },
            },
          },
          yAxis: {
            show: false,
            type: "value",
          },
          series: tmpSeries,
        },
        true
      );
    },
    getoperateTotalReturnRoi(type, params) {
      let beginDate = null;
      let endDate = null;
      switch (type) {
        case 2:
          beginDate = getPreMonth()[0];
          endDate = getPreMonth()[1];
          break;
        case 3:
          beginDate = getPresentMonth()[0];
          endDate = getPresentMonth()[1];
          break;
        case 4:
          beginDate = getRecentlyMonth()[0];
          endDate = getRecentlyMonth()[1];
          break;
        default:
          beginDate = null;
          endDate = null;
          break;
      }

      operateTotalReturnRoi({
        dataType: this.dataType,
        orderType: 1,
        beginDate,
        endDate,
        sortName: this.sortName,
        sortDesc: this.sortDesc,
        ...this.params,
      }).then((res) => {
        this.totalReturnRoi = [
          {
            name: "投放",
            value: res.putAmt,
          },
          {
            name: "回本",
            value: res.returnAmt,
          },
          {
            name: "利润",
            value: res.profitAmt,
          },
          {
            name: "日充",
            value: res.everydayRecharge,
          },
          {
            name: "roi",
            value: res.roi,
          },
        ];

        this.booksRoi = res.booksRoi;
        this.booksRoiPageData = this.booksRoi.slice(0, this.booksRoiPageSize);
        this.booksRoiTotalPage = Math.ceil(
          this.booksRoi.length / this.booksRoiPageSize
        );
        this.channelsRoi = res.channelsRoi;
        this.channelsRoiPageData = this.channelsRoi.slice(
          0,
          this.channelsRoiPageSize
        );
        this.channelsRoiTotalPage = Math.ceil(
          this.channelsRoi.length / this.channelsRoiPageSize
        );
      });
      operateMonthRoi({ orderType: 1, ...this.params }).then((res) => {
        this.MonthRoiData = res;
        this.initmonthlyROI();
      });
    },
    handleShowDrawer(item, timeTpe = "date") {
      if (["cashCost", "channelNum"].includes(item.eName)) {
        this.showTemplate = "channelConsume";
      }
      if (item.eName === "booksNum") {
        this.showTemplate = "bookConsume";
      }
      if (item.eName === "adNum") {
        this.showTemplate = "adConsume";
      }

      // let tmpQuery =
      //   timeTpe === "date"
      //     ? formatTime(new Date(), "yyyy-MM-dd")
      //     : formatTime(new Date(), "yyyy-MM-dd 23:59:59");
      let tmpQuery =
        timeTpe === "date"
          ? this.params.queryDate
          : this.params.queryDate + " 23:59:59";
      if (this.showTemplate !== "putProject") {
        this.nextParams = {
          ...this.params,
          queryDate: tmpQuery,
        };
      } else {
        this.nextParams = {
          putData: {
            ...this.params,
            selectTime: [tmpQuery, tmpQuery],
            bookId: this.params.booksId,
          },
        };
      }
      this.showCommonDrawer = true;
    },
    handleSearch() {
      this.addRouterHistory({
        path: this.$route.path,
        params: {
          booksId: this.params.booksId,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.accountChannelId,
          adminUserId: this.params.adminUserId,
          deptId: this.params.deptId,
          queryDate: this.params.queryDate,
        },
      });
      this.initData();
    },
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    checkMore() {},
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    handleBookIdChange(val) {},
    handleBookClear() {
      this.params.booksId = null;
    },
    changeDataRange(type = "total") {},
    getpercentage(row) {
      return row.roi >= 100 ? 100 : row.roi;
    },
    getReturnRate() {
      let value = this.fieldList.find((item) => item.name === "returnRate");

      return value ? parseInt(value.value) : 0;
    },
    handleTotalReturnTabChange(e) {
      this.totalReturnTab = parseInt(e.target.dataset.id);
      this.getoperateTotalReturnRoi(this.totalReturnTab);
    },
    handleBookSortChange({ column, prop, order }) {
      this.dataType = 1;
      if (order === "descending") {
        this.sortName = prop;
        this.sortDesc = true;
      } else if (order === "ascending") {
        this.sortName = prop;
        this.sortDesc = false;
      } else {
        this.sortName = null;
        this.sortDesc = null;
      }
      this.getoperateTotalReturnRoi(this.totalReturnTab);
    },
    handleChannelSortChange({ column, prop, order }) {
      this.dataType = 2;
      if (order === "descending") {
        this.sortName = prop;
        this.sortDesc = true;
      } else if (order === "ascending") {
        this.sortName = prop;
        this.sortDesc = false;
      } else {
        this.sortName = null;
        this.sortDesc = null;
      }
      this.getoperateTotalReturnRoi(this.totalReturnTab);
    },
    handleTablePageChange(flag, type) {
      if (type === "books") {
        if (flag === "go") {
          this.booksPage =
            this.booksPage <
            Math.ceil(this.booksRoi.length / this.booksRoiPageSize)
              ? this.booksPage + 1
              : this.booksPage;
        } else if (flag === "back") {
          this.booksPage =
            this.booksPage > 1 ? this.booksPage - 1 : this.booksPage;
        }
        this.tableStaticPage(this.booksPage, type);
      } else if (type === "channels") {
        if (flag === "go") {
          this.channelsPage =
            this.channelsPage <
            Math.ceil(this.channelsRoi.length / this.channelsRoiPageSize)
              ? this.channelsPage + 1
              : this.channelsPage;
        } else if (flag === "back") {
          this.channelsPage =
            this.channelsPage > 1 ? this.channelsPage - 1 : this.channelsPage;
        }
        this.tableStaticPage(this.channelsPage, type);
      }
    },
    tableStaticPage(page, type) {
      if (type === "books") {
        this.booksRoiPageData = this.booksRoi.slice(
          this.booksRoiPageSize * (page - 1),
          this.booksRoiPageSize * page
        );
      } else {
        this.channelsRoiPageData = this.channelsRoi.slice(
          this.channelsRoiPageSize * (page - 1),
          this.channelsRoiPageSize * page
        );
      }
    },
    handleReset() {
      this.params.booksId = null;
      this.params.originPlatform = null;
      this.params.accountChannelId = null;
      if (this.userInfo.homeRole.includes("0")) {
        this.params.adminUserId = null;
      }
      this.params.deptId = null;
      this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
      this.initData("updated");
    },
    deepClone(val) {
      return JSON.parse(JSON.stringify(val));
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
  },
  components: {
    SelectPlatformChannel,
    PercentageRuler,
    DuPontPicture,
  },
};
</script>
<style lang="scss" scoped>
a {
  color: #459ae9;
}
.home-page {
  height: 100%;
  position: relative;
}
.no-date {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .no-data-text {
    text-align: center;
    margin-top: 30px;
    color: #8492a6;
  }
}

/deep/ .el-progress-bar__outer {
  // background: #003866 !important;
}
.is-dark-blue {
  color: #0024ff !important;
}
.orange-color {
  color: #ffa700 !important;
}

.field-parts {
  display: flex;
  justify-content: space-between;
  margin: 0 5px;
  .field-part {
    > p:nth-of-type(1) {
      margin-bottom: 1.5vh;
      font-size: 12px;
      color: #969696;
    }
    > p:nth-of-type(2) {
      font-weight: bold;
      // color: #51acff;
      font-size: 14px;
    }
  }
  .field-part-payback {
    margin-right: 2vw;
    > p:nth-of-type(2) {
      color: #00ccff;
    }
  }
}
h3 {
  font-size: 1em;
  padding-left: 0.5vw;
  padding-bottom: 0.5vw;
  color: #969696;
  margin-bottom: 2.5em;
}
.text-center {
  text-align: center;
}
.el-icon-bottom {
  color: #1a8c1a;
}
.el-icon-top {
  color: #d33034;
}
.putter-board {
  background: #fff;
  .putter-operation {
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .operation-part {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 1vw;
      // margin-right: 5rem;
      > span {
        padding-right: 1vw;
      }
    }
  }
}

.sq-graph {
  #book-consume {
    height: 250px;
  }
  #channel-consume {
    height: 250px;
  }
}

.book-rate {
  margin-top: 10px;
  .title {
    font-weight: bold;
  }
  #delivery-conversion-rate {
    // margin-left: 1vw;
    // flex-grow: 1;
    height: 200px;
  }
}
.follower-info {
  margin-top: 10px;
  #follower {
    height: 90px;
  }
}

.today-recharge-bottom-part {
  margin-top: 2vw;
  .title {
    color: #969696;
    // font-size: 0.7em;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    margin: 0;
  }
  .book-list {
    margin-top: 1vw;
    margin-left: 1vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    .book-item {
      margin-right: 0.8vw;
      margin-bottom: 0.5vw;
      max-width: 90px;
      color: red;
      font-size: 0.7em;
    }
    .check-more {
      right: 0;
      font-size: 12px;
      color: #969696;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
.days-payconsume-15 {
  margin-top: 30px;
  position: relative;
  #days-payconsume-15 {
    height: 110px;
  }
}
.days-payback-15 {
  margin-top: 20px;
  #days-payback-15 {
    width: 100%;
    height: 120px;
  }
}

.user-info {
  margin-top: 51px;
  font-size: 12px;
  color: #969696;
  .title {
    font-weight: bold;
  }
  #vip-recharge {
    height: 200px;
  }
  #user-category {
    height: 200px;
  }
}

.el-card {
  border: none;
  /deep/ .el-card__header {
    padding: 12px 0;
  }
  /deep/ .el-card__body {
    padding: 0;
  }
}

.el-card .card-title {
  display: inline-block;
  color: #969696;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
}

.put-item {
  padding: 4px 0;
  cursor: pointer;
  h4 {
    height: 14px;
    line-height: 14px;
    color: #475669;
    font-size: 14px;
    font-weight: 400;
    padding-left: 7px;
    border-radius: 2px;
  }
  .put-1 {
    border-left: 4px solid #3f80ff;
  }
  .put-2 {
    border-left: 4px solid #22c7b1;
  }
  .put-3 {
    border-left: 4px solid #ffb85e;
  }
  .put-4 {
    border-left: 4px solid #6273e0;
  }
  .put-5 {
    border-left: 4px solid #b47eed;
  }
  .put-6 {
    border-left: 4px solid #fc8c8a;
  }
  .put-item-value {
    padding: 5px 0 5px 10px;
    // padding-right:0;
    .icon-xiajiang {
      font-size: 12px;
    }
  }
  .total-value {
    font-size: 24px;
    font-weight: bold;
    color: #1f2d3d;
  }
  .change-value-red {
    margin-left: auto;
    font-size: 12px;
    color: #fd5d5a;
  }
  .change-value-green {
    margin-left: auto;
    font-size: 12px;
    color: #22c7b1;
  }
}
.total-return {
  position: relative;
  .card-title {
    // padding: 0 40px;
    .title {
      font-weight: bold;
    }
  }
  .card-title-tab {
    position: absolute;
    top: 10px;
    right: 20px;
    .active {
      color: #579bf4;
    }
    span {
      padding-right: 25px;
      font-size: 12px;
      color: #8492a6;
      cursor: pointer;
    }
  }
  .el-link {
    padding: 0 5px;
  }

  #MonthRoi {
    height: 200px;
  }
  // 书籍roi表格
  .books-roi {
    .title {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-text {
        color: #1f2d3d;
        font-size: 16px;
        font-weight: bold;
      }
      .change-page {
        margin-left: 20px;
        /deep/ .el-button--mini {
          padding: 0px 3px !important;
          .el-icon--right {
            margin: 0 !important;
          }
        }
        .page-value {
          margin-right: 5px;
          font-size: 12px;
          text-align: center;
          margin-top: 1px;
          color: #8492a6;
        }
      }
    }
    /deep/ td {
      padding: 5px !important;
    }
    /deep/ .is-leaf {
      padding: 0 !important;
    }
    /deep/ .has-gutter tr {
      height: 30px !important;
    }
    /deep/ tr td {
      font-size: 12px !important;
    }
    /deep/ thead tr th {
      font-size: 12px !important;
    }
    /deep/ th > .cell {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}
.charge-bar {
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bar-item1,
  .bar-item3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title {
      font-size: 12px;
      color: #8492a6;
    }
    .value {
      // color: #579BF4;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .bar-item3 {
    .value {
      font-size: 14px;
    }
  }
  .bar-item3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title {
      font-size: 12px;
      color: #8492a6;
    }
    .value {
      // color: #579BF4;
      font-size: 12px;
      font-weight: bold;
    }
    .returnRate {
      color: #ffa700;
    }
  }
}
.check-all {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 150px;
  overflow: auto;
  margin-left: 5px;
  span {
    margin-bottom: 3px;
  }
}
#MonthRoi {
  height: 200px;
}

.el-table {
  color: rgb(150, 150, 150) !important;
}
// 杜邦
.duPont-drawer /deep/ .el-drawer__header {
  margin-bottom: 0 !important;
}
.duPont-drawer /deep/ .el-drawer__header > :first-child {
  text-align: center;
}
</style>
